import { FC, useEffect, useState } from 'react';
import { Button, Skeleton } from '@mui/material';
import CartItem from './Item';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import {
  dineroFormat,
  dineroGetPercentage as dineroGetPercentage,
  fromISOtoFormat
} from 'src/common/utils/dataFormat';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IOrder } from 'src/models/IOrder';
import './styles.scss';
import './media.scss';

interface IProps {
  order: IOrder;
  titleButton: string;
  handleNextStep?: () => void;
  handleBackStep?: () => void;
  onChange: (name: string, value: any) => void;
}

const Cart: FC<IProps> = ({
  order,
  titleButton = 'Next step',
  handleNextStep,
  handleBackStep,
  onChange
}) => {
  const [step, setStep] = useState<string>('');
  useEffect(() => {
    titleButton === 'Continue to pay' && setStep('2');
    titleButton === 'Reserve van' && setStep('3');
  }, [titleButton]);
  const { start_date, end_date, location, sub_location, end_location } = useTypedSelector(
    state => state.van.filters
  );
  const { extras, van, price, currency } = order;

  const _orderItems = () => (
    <>
      {van ? (
        <CartItem item={van} order={order} title='van' step={step} onChange={onChange} />
      ) : (
        <Skeleton width='100%' height='55px' />
      )}
      {extras.map((extra, key) => (
        <CartItem
          item={extra}
          order={order}
          title='extra'
          key={key}
          step={step}
          onChange={onChange}
        />
      ))}
    </>
  );

  return (
    <div className='order-cart'>
      <div className='order-cart__header'>
        <div style={{ fontWeight: 600 }}>Booking</div>
        <div className='order-cart__header__date'>
          <div className='order-cart__header__location'>{location}</div>
          <div className='order-cart__header__sub-location'>{sub_location}</div>
          <div className='order-cart__header__date'>{fromISOtoFormat(start_date!, 'ff')}</div>
          <div className='order-cart__header__location'>{end_location || ''}</div>
          <div>{fromISOtoFormat(end_date!, 'ff')}</div>
          <p style={{ marginTop: '10px' }}>You can pick your van 24/7 with a reservation.</p>
        </div>
      </div>
      <div className='order-cart__wrapper'>{_orderItems()}</div>
      <div className='order-cart__total-price'>
        <p className='order-cart__total-price__title'>Total incl. all fees / taxes</p>
        <div className='extra-card__price order-cart__total-price__price'>
          {dineroFormat(price, currency)}
        </div>
      </div>
      <div className='order-cart__wrapper-total'>
        <div className='order-cart__wrapper-total__info'>
          <p className='order-cart__wrapper-total__info__price'>
            {dineroFormat(dineroGetPercentage(price, 20), currency)}
          </p>
          <p className='order-cart__wrapper-total__info__title'>
            Refundable down payment to reserve your van
          </p>
          <p className='order-cart__wrapper-total__info__subtitle'>
            Applied towards your rental, fully refundable.
          </p>
        </div>
        <div
          className={`
            order-cart__wrapper-total__buttons 
            ${!handleNextStep ? 'stripe' : ''}
          `}
        >
          {handleBackStep && handleNextStep && (
            <SecondTypeButton
              children='Back'
              onClick={handleBackStep}
              sx={{
                maxWidth: `80px`,
                '@media screen and (max-width: 991px)': { maxWidth: `100%` }
              }}
            />
          )}
          {handleNextStep && (
            <Button
              className='order-cart__button'
              children={titleButton}
              onClick={handleNextStep}
              variant='contained'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
