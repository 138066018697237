import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat } from 'src/common/utils/dataFormat';
import { IExtra, IOrder } from 'src/models/IOrder';
import { IVanFetch } from 'src/models/IVan';
import Icons from 'src/common/icons';

interface IProps {
  order: IOrder;
  item: IVanFetch | IExtra;
  title: 'van' | 'extra';
  step?: string;
  onChange: (name: string, value: any) => void;
}

const CartItem: FC<IProps> = ({ order, item, title, step, onChange }) => {
  const { clearOrder } = useActions();
  const navigate = useNavigate();
  const { sub_location } = useTypedSelector(state => state.van.filters);
  const subId = process.env.REACT_APP_NODE_ENV ? 18 : 27;

  const handleClearOrder = () => {
    clearOrder();
    localStorage.clear();
    navigate('/');
  };

  const handleRemove = () => {
    if (title === 'van') handleClearOrder();
    else {
      onChange(
        'extras',
        order.extras.filter(ext => ext.id !== item?.id)
      );
    }
  };

  let changedExtras: IExtra[];
  const handleRemoveAdditionalSeat = () => {
    (changedExtras = order.extras),
      changedExtras.splice(order.extras.indexOf(item as IExtra), 1),
      onChange('extras', changedExtras);
  };

  return title === 'van' ? (
    <div className='order-cart__item'>
      <div className='order-cart__item__img'>
        <img src={(item as IVanFetch).photos?.exterior[0]} alt='van' />
      </div>
      <div className='order-cart__item__info'>
        <div className='order-cart__item__info__title'>{(item as IVanFetch).mark}</div>
        <div className='order-cart__item__info__description'>{(item as IVanFetch).model}</div>
      </div>
      <div className='order-cart__item__price'>
        {`${dineroFormat(item.price, item.currency)}/day`}
      </div>
      <div className='order-cart__item__remove' onClick={handleRemove}>
        {step !== '3' && <Icons.Delete />}
      </div>
    </div>
  ) : (
    <div className='order-cart__item'>
      <div className='order-cart__item__img'>
        {(item as IExtra).id === subId ? (
          <AddLocationAltIcon fontSize='large' />
        ) : (
          <img src={(item as IExtra).logo} alt='extra' />
        )}
      </div>
      <div className='order-cart__item__info'>
        <div className='order-cart__item__info__title'>
          {(item as IExtra).id === subId ? sub_location : (item as IExtra).name}
        </div>
        <div className='order-cart__item__info__description'>{(item as IExtra).title}</div>
      </div>
      <div className='order-cart__item__price'>{dineroFormat(item.price, item.currency)}</div>
      {(item as IExtra).isClientEdit ? (
        <div
          className='order-cart__item__remove'
          onClick={
            (item as IExtra).id !== 12 && (item as IExtra).id !== 41
              ? handleRemove
              : handleRemoveAdditionalSeat
          }
        >
          {step !== '3' && <Icons.Delete />}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default CartItem;
