import { IClientFetch, IOrderChangeDates, IOrderForClient } from "src/models/IOrder";

export enum ClientActionEnum {
	REQUEST_CLIENT = "REQUEST_CLIENT",
	RESULT_CLIENT = "RESULT_CLIENT",

	REQUEST_UPDATE_CLIENT = "REQUEST_UPDATE_CLIENT",
	RESULT_UPDATE_CLIENT = "RESULT_UPDATE_CLIENT",

	REQUEST_UPDATE_ORDER = "REQUEST_UPDATE_ORDER",
	RESULT_UPDATE_ORDER = "RESULT_UPDATE_ORDER",

	REQUEST_CLIENT_PAY = "REQUEST_CLIENT_PAY",
	RESULT_CLIENT_PAY = "RESULT_CLIENT_PAY",

	REQUEST_SIGNUP = "REQUEST_SIGNUP",
	RESULT_SIGNUP = "RESULT_SIGNUP",

	CHECK_TOKEN = "CHECK_TOKEN",
	RESULT_CHECK_TOKEN = "RESULT_CHECK_TOKEN",

	REQUEST_LOGIN = "REQUEST_LOGIN",
	RESULT_LOGIN = "RESULT_LOGIN",
	ERROR_LOGIN = "ERROR_LOGIN",
	CLEAR_CLIENT_RESULT = "CLEAR_CLIENT_RESULT",
	EXIT = "EXIT",

	REQUEST_SHOW_CONTRACT = "REQUEST_SHOW_CONTRACT",
	RESULT_SHOW_CONTRACT = "RESULT_SHOW_CONTRACT",
	REQUEST_SHOW_LICENSE = "REQUEST_SHOW_LICENSE",
	RESULT_SHOW_LICENSE = "RESULT_SHOW_LICENSE",
	REQUEST_SHOW_INSURANCE = "REQUEST_SHOW_INSURANCE",
	RESULT_SHOW_INSURANCE = "RESULT_SHOW_INSURANCE",

	REQUEST_SEND_RECEIPT = "REQUEST_SEND_RECEIPT",
	RESULT_SEND_RECEIPT = "RESULT_SEND_RECEIPT",

	REQUEST_CHANGE_DATES = "REQUEST_CHANGE_DATES",
	RESULT_CHANGE_DATES = "RESULT_CHANGE_DATES",
}

export interface Base64Object {
	fileName: string;
	fileType: string;
	base64: string;
}

export interface ClientState {
	client?: IClientFetch;
	orderList?: IOrderForClient[];
	isLogin: boolean;
	isError: boolean;
	isLoading: {
		login: boolean;
		signup: boolean;
		get: boolean;
		update: boolean;
		pay: boolean;
		order: boolean;
		check: boolean;
		doc: boolean;
		receipt: boolean;
		dates: boolean;
	};
	result: {
		login?: number | string;
		signup?: any;
		get?: any;
		update?: number | string;
		pay?: string;
		order?: number | string;
		check?: number | string;
		doc?: Base64Object;
		receipt?: number | string;
		dates?: number | string;
	};
}

export interface RequestPayNowAction {
	type: ClientActionEnum.REQUEST_CLIENT_PAY;
	id: number;
}

export interface ResultPayNowAction {
	type: ClientActionEnum.RESULT_CLIENT_PAY;
	data: string;
	status: string | number;
}

export interface RequestClientAction {
	type: ClientActionEnum.REQUEST_CLIENT;
	id: string;
}

export interface ResultClientAction {
	type: ClientActionEnum.RESULT_CLIENT;
	data: any;
	status: string | number;
}

export interface UpdateClientAction {
	type: ClientActionEnum.REQUEST_UPDATE_CLIENT;
	currentClient: Partial<IClientFetch>;
}
export interface ResultUpdateClientAction {
	type: ClientActionEnum.RESULT_UPDATE_CLIENT;
	status: number;
}

export interface UpdateOrderAction {
	type: ClientActionEnum.REQUEST_UPDATE_ORDER;
	currentOrder: Partial<IOrderForClient>;
}
export interface ResultUpdateOrderAction {
	type: ClientActionEnum.RESULT_UPDATE_ORDER;
	status: number;
}

export interface SignupAction {
	type: ClientActionEnum.REQUEST_SIGNUP;
	email: string;
}

export interface ResultSignupAction {
	type: ClientActionEnum.RESULT_SIGNUP;
	status: number | string;
}

export interface CheckTokenAction {
	type: ClientActionEnum.CHECK_TOKEN;
	token: string;
}

export interface ResultCheckTokenAction {
	type: ClientActionEnum.RESULT_CHECK_TOKEN;
	status: number;
}

export interface ErrorLoginAction {
	type: ClientActionEnum.ERROR_LOGIN;
}

export interface ExitLoginAction {
	type: ClientActionEnum.EXIT;
	result?: string;
}

export interface ClearClientResultAction {
	type: ClientActionEnum.CLEAR_CLIENT_RESULT;
}

export interface ShowContractAction {
	type: ClientActionEnum.REQUEST_SHOW_CONTRACT;
	key: string;
}
export interface ResultShowContractAction {
	type: ClientActionEnum.RESULT_SHOW_CONTRACT;
	data: Base64Object;
}

export interface ShowLicenseAction {
	type: ClientActionEnum.REQUEST_SHOW_LICENSE;
	key: string;
}
export interface ResultShowLicenseAction {
	type: ClientActionEnum.RESULT_SHOW_LICENSE;
	data: Base64Object;
}

export interface ShowInsuranceAction {
	type: ClientActionEnum.REQUEST_SHOW_INSURANCE;
	key: string;
}
export interface ResultShowInsuranceAction {
	type: ClientActionEnum.RESULT_SHOW_INSURANCE;
	data: Base64Object;
}

export interface SendReceiptAction {
	type: ClientActionEnum.REQUEST_SEND_RECEIPT;
	orderId: number;
	handlePaymentId?: string;
}
export interface ResultSendReceiptAction {
	type: ClientActionEnum.RESULT_SEND_RECEIPT;
	status: number;
	data?: string;
}

export interface ChangeOrderDatesAction {
	type: ClientActionEnum.REQUEST_CHANGE_DATES;
	dates: IOrderChangeDates;
	orderId: number;
}
export interface ResultChangeOrderDatesAction {
	type: ClientActionEnum.RESULT_CHANGE_DATES;
	status: number;
	data?: IOrderForClient;
}

export type ClientAction =
	| RequestClientAction
	| ResultClientAction
	| ErrorLoginAction
	| ExitLoginAction
	| SignupAction
	| ResultSignupAction
	| RequestPayNowAction
	| ResultPayNowAction
	| UpdateOrderAction
	| ResultUpdateOrderAction
	| UpdateClientAction
	| ResultUpdateClientAction
	| CheckTokenAction
	| ResultCheckTokenAction
	| ClearClientResultAction
	| ShowContractAction
	| ResultShowContractAction
	| ShowLicenseAction
	| ResultShowLicenseAction
	| ShowInsuranceAction
	| ResultShowInsuranceAction
	| SendReceiptAction
	| ResultSendReceiptAction
	| ChangeOrderDatesAction
	| ResultChangeOrderDatesAction;
