import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import LeadCard from './LeadCard';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import SmallFooter from 'src/components/Footer/SmallFooter';
import CheckoutForm from '../Order/Stripe';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { AvailableLead, LeadData } from 'src/store/reducers/order/types';
import './media.scss';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const CheckQuotePage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { checkQuoteOrder, setupFilters } = useActions();
  const { result, quoteCheck } = useTypedSelector(state => state.order);
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    if (id) checkQuoteOrder(id);
  }, [id]);

  useEffect(() => {
    if (result.check) {
      if (result.check === 200) {
        if (!(quoteCheck as AvailableLead)!.clientSecret) {
          setIsAvailable(false);
          navigate('/');
          setupFilters({ ...(quoteCheck as LeadData), page: 1, take: 10 });
          enqueueSnackbar('Unfortunately, the van is already booked. Please choose another one.', {
            variant: 'info'
          });
        } else setIsAvailable(true);
      } else navigate('/');
    }
  }, [result.check]);

  return (
    <>
      <div className='header'>
        <SmallHeader />
      </div>
      <div className='order-payment check-quote'>
        <div className='col-2 order-payment__container'>
          <div className='order-payment__container__cart-container'>
            {isAvailable ? (
              <LeadCard order={(quoteCheck as AvailableLead).order} />
            ) : (
              <Skeleton sx={{ maxWidth: '100%' }} height='100%' style={{ transform: 'none' }} />
            )}
          </div>
          <div className='order-payment__price-off'>
            <div className='order-payment__container__payment-container'>
              <p>
                You can book now with a fully refundable down payment applied towards your rental.
              </p>
              <p>
                We'll email a secure link to pay your remainder before your rental. You may pay with
                the same or a different card.
              </p>
              <p>
                You may
                <a
                  href='https://www.sprinter-rentals.com/cancellation'
                  target='_blank'
                  style={{ textDecoration: 'underline' }}
                >
                  cancel
                </a>
                free up to 5 days prior to your trip.
              </p>
            </div>
            <div className='order-payment__payment'>
              {isAvailable ? (
                <Elements
                  options={{
                    clientSecret: (quoteCheck as AvailableLead).clientSecret,
                    appearance: {
                      theme: 'stripe'
                    }
                  }}
                  stripe={stripePromise}
                >
                  <CheckoutForm startDate={Date.now().toString()} />
                </Elements>
              ) : (
                <Skeleton sx={{ maxWidth: '100%' }} height={230} style={{ transform: 'none' }} />
              )}
            </div>
          </div>
        </div>
      </div>
      <SmallFooter />
    </>
  );
};

export default CheckQuotePage;
