import { IClient, IClientFetch, IOrder } from 'src/models/IOrder';
import { IVanFetch } from 'src/models/IVan';
import {
  AvailableLead,
  ChangeOrderAction,
  ClearOrderAction,
  InitOrderAction,
  LeadData,
  OrderActionEnum,
  RequestAddClientAction,
  RequestBookOrderAction,
  RequestCheckQuoteOrderAction,
  RequestQuoteOrderAction,
  ResultAddClientAction,
  ResultBookOrderAction,
  ResultCheckQuoteOrderAction,
  ResultQuoteOrderAction,
  SetupOrderAction
} from './types';

export const OrderActionCreators = {
  initOrder: (): InitOrderAction => ({
    type: OrderActionEnum.INIT_ORDER
  }),
  changeOrder: (name: string, value: any): ChangeOrderAction => ({
    type: OrderActionEnum.CHANGE_ORDER,
    name,
    value
  }),
  setupOrder: (van: IVanFetch): SetupOrderAction => ({
    type: OrderActionEnum.SETUP_ORDER,
    van
  }),
  clearOrder: (): ClearOrderAction => ({
    type: OrderActionEnum.CLEAR_ORDER
  }),
  addClient: (client: IClient): RequestAddClientAction => ({
    type: OrderActionEnum.REQUEST_ADD_CLIENT,
    client
  }),
  resultAddClient: (client: IClientFetch, status: string | number): ResultAddClientAction => ({
    type: OrderActionEnum.RESULT_ADD_CLIENT,
    status,
    client
  }),
  bookOrder: (dataOrder: IOrder): RequestBookOrderAction => ({
    type: OrderActionEnum.REQUEST_BOOK_ORDER,
    dataOrder
  }),
  resultBookOrder: (statusText: string): ResultBookOrderAction => ({
    type: OrderActionEnum.RESULT_BOOK_ORDER,
    statusText
  }),
  quoteOrder: (id: number): RequestQuoteOrderAction => ({
    type: OrderActionEnum.REQUEST_QUOTE_ORDER,
    id
  }),
  resultQuoteOrder: (statusText: string): ResultQuoteOrderAction => ({
    type: OrderActionEnum.RESULT_QUOTE_ORDER,
    statusText
  }),
  checkQuoteOrder: (id: string | number): RequestCheckQuoteOrderAction => ({
    type: OrderActionEnum.REQUEST_CHECK_QUOTE,
    id
  }),
  resultCheckQuoteOrder: (
    status: number,
    data?: AvailableLead | LeadData
  ): ResultCheckQuoteOrderAction => ({
    type: OrderActionEnum.RESULT_CHECK_QUOTE,
    status,
    data
  })
};
